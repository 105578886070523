const videos = [
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Disthyroidie et cœur",
    speaker: "Dr A. ADIMI",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Obésité et retentissement cardio vasculaire",
    speaker: "Dr H. DJORANE ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Interrêt de l'impudence-métrie dans l'obèsité",
    speaker: "Dr F. BOUYOUCEF",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
  {
    title: "Cœur et acromégalie",
    speaker: "Pr L. AHMED ALI ",
    url: "https://www.youtube.com/watch?v=3qIM9CtJL4Q"
  },
]

const speakers = [
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
  {
    name: "Pr L. AHMED ALI",
    position: "CHU BAB EL OUED"
  },
]
const wilayas = [
  "Adrar",
  "Chlef",
  "Laghouat",
  "Oum El Bouaghi",
  "Batna",
  "Béjaïa",
  "Biskra",
  "Béchar",
  "Blida",
  "Bouïra",
  "Tamanrasset",
  "Tébessa",
  "Tlemcen",
  "Tiaret",
  "Tizi Ouzou",
  "Alger",
  "Djelfa",
  "Jijel",
  "Sétif",
  "Saïda",
  "Skikda",
  "Sidi Bel Abbès",
  "Annaba",
  "Guelma",
  "Constantine",
  "Médéa",
  "Mostaganem",
  "M'Sila",
  "Mascara",
  "Ouargla",
  "Oran",
  "El Bayadh",
  "Illizi",
  "Bordj Bou Arréridj",
  "Boumerdès",
  "El Tarf",
  "Tindouf",
  "Tissemsilt",
  "El Oued",
  "Khenchela",
  "Souk Ahras",
  "Tipaza",
  "Mila",
  "Aïn Defla",
  "Naâma",
  "Aïn Témouchent",
  "Ghardaïa",
  "Relizane",
  "El M'Ghair",
  "El Menia",
  "Ouled Djellal",
  "Bordj Baji Mokhtar",
  "Béni Abbès",
  "Timimoun",
  "Touggourt",
  "Djanet",
  "In Salah",
  "In Guezzam"
]

const specialite = [
  "Endocrinologue",
  "Diabetologue",
  "Généraliste",
  "Cardiologue",
  "Oncologue",
  "Pédiatre",
  "Interniste",
  "Autre",
]

export {videos, speakers, wilayas, specialite}