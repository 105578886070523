import {gql} from "@apollo/client";

const INSERT_REGISTRATION = gql`
    mutation insertOneRegistration($data:RegistrationInsertInput!){
        insertedRegistration:insertOneRegistration(data:$data){
            _id
            email
            infos
        }
    }
`

export {INSERT_REGISTRATION}