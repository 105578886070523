import React from 'react';
import {Button, Form, Input, Select, Tag} from "antd";
import {CheckOutlined} from "@ant-design/icons"
import {useInsertRegistration} from "../../hooks/registration";
import {specialite, wilayas} from "../../utils/constants";
import {Link} from "gatsby";

const {Option} = Select


export default function RegistrationForm() {
  const [form] = Form.useForm()
  const {insertRegistration, loading} = useInsertRegistration()
  
  function register() {
    form.validateFields()
      .then(values => {
        insertRegistration(values)
      })
  }
  
  return (
    <Form
      labelCol={{xs: 24, sm: 3}}
      wrapperCol={{xs: 24, sm: 18}}
      form={form}
    >
      <Form.Item
        label="Nom"
        name="Nom"
        rules={[
          {
            required: true,
            message: 'Veuillez saisir le nom!',
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Prénom"
        name="Prénom"
        rules={[
          {
            required: true,
            message: 'Veuillez saisir le prénom!',
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="E-mail"
        rules={[
          {
            required: true,
            message: "Veuillez saisir l'e-mail!",
          },
          {
            type: "email",
            message: "Veuillez saisir un e-mail valide!",
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Tel"
        name="Tel"
        rules={[
          {
            required: true,
            message: "Veuillez saisir le téléphone!",
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Spécialité"
        name="Spécialité"
        rules={[
          {
            required: true,
            message: "Veuillez choisir la spécialité!",
          },
        ]}
      >
        <Select
          placeholder="Selectionner une spécialité"
          showSearch
          filterOption={true}
        >
          {specialite.map((specialite, i) => (
            <Option key={specialite} value={specialite}>
              {specialite}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Affiliation"
        name="Affiliation"
        rules={[
          {
            required: true,
            message: "Veuillez choisir l'affiliation!",
          },
        ]}
      >
        <Select
          options={[{value: "Publique", label: "Publique"}, {
            value: "Privé",
            label: "Privé"
          }]}
          placeholder="Selectionner une affiliation"
          showSearch
          filterOption={true}
        />
      </Form.Item>
      <Form.Item
        label="Wilaya"
        name="Wilaya"
        rules={[
          {
            required: true,
            message: "Veuillez selectionner la wilaya!",
          },
        ]}
      >
        <Select
          placeholder="Selectionner une wilaya"
          showSearch
          filterOption={true}
        >
          {wilayas.map((wilaya, i) => (
            <Option key={wilaya} value={wilaya}>
              {i + 1}{"- " + wilaya}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label=" "
        colon={false}
      >
        <Button
          type="primary"
          icon={<CheckOutlined/>}
          size="large"
          block
          onClick={register}
          loading={loading}
        >
          Inscription
        </Button>
      </Form.Item>
      <Form.Item
        label=" "
        colon={false}
      >
        <div style={{textAlign: "center"}}>
          Déjà inscrit? <Link to={"/"}><Tag color="#108ee9">Connetez vous!</Tag></Link>
        </div>
      </Form.Item>
    </Form>
  );
}