import React from 'react';
import {Card, Col, Grid, Row, Typography} from "antd";
import {StaticImage} from "gatsby-plugin-image";
import Seo from "../seo";
import RegistrationForm from "./registrationForm";

const {useBreakpoint} = Grid;
const {Title} = Typography
export default function Registration() {
  const screens = useBreakpoint();
  return (
    <Row justify="center">
      <Seo title="Inscription"/>
      <Col sm={14} xs={24}>
        <Card
          style={{minHeight: "100vh", backgroundColor: "rgba(255,255,255,0.5)"}}
          cover={<StaticImage src={"../../images/banner.jpg"} alt={"banner"}/>}
        >
          <div style={{textAlign: "center", marginBottom: 20}}>
            <Title
              level={screens.xs ? 4 : 2}
              style={{color: "rgb(0,6,98)"}}
            >
              Formulaire d'inscription
            </Title>
          </div>
          <RegistrationForm/>
        </Card>
      </Col>
    </Row>
  );
}