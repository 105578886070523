import React from "react"
import {useMutation} from "@apollo/client";
import {Modal, Result} from "antd";
import {INSERT_REGISTRATION} from "../apollo/mutations/registration";
import {useLocalStorage} from "react-use";
import {navigate} from "gatsby";


function useInsertRegistration() {
  const [insertOneRegistration, {loading}] = useMutation(INSERT_REGISTRATION)
  const [value, setValue] = useLocalStorage("userId")
  
  async function insertRegistration(registration) {
    try {
      const data = await insertOneRegistration({
        variables: {
          data: {
            event: {link: process.env.GATSBY_EVENT_ID},
            email: registration["E-mail"],
            infos: JSON.stringify(registration)
          },
        },
      })
      console.log(data.data.insertedRegistration._id)
      setValue(data?.data?.insertedRegistration?._id)
      Modal.success({
        width: "auto",
        icon: undefined,
        content: <Result
          status="success"
          title={"Félicitations " + registration["Nom"].toUpperCase() + " " + registration["Prénom"] + " !"}
          subTitle={"Votre inscription à bien été effectué avec l'adresse " + registration["E-mail"]}
        />,
        footer: null,
        centered: true
      })
      navigate("/")
      return true
    } catch (e) {
      console.log(e)
      let show = "Erreur dans l'inscription"
      if (e.toString().toLowerCase().includes("duplicate")) {
        show = "Cette addresse mail est déjà utilisé!"
      }
      Modal.error({
        width: "auto",
        content: show,
        centered: true
      })
      return false
    }
  }
  
  return {
    loading,
    insertRegistration
  }
}

export {useInsertRegistration}